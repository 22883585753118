import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js/core';
import SHA256 from 'crypto-js/sha256';
import { Observable } from 'rxjs';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import 'crypto-js/lib-typedarrays';

@Injectable({
  providedIn: 'root'
})
export class HashHelperService {
  constructor() {
    let _sha256 = SHA256;
    let hmacSha = HmacSHA256;
  }

  hashSha256Hex(payload: any): string {
    let stringPayload =
      !!payload && typeof payload !== 'string'
        ? JSON.stringify(payload)
        : payload;
    const hash = CryptoJS.SHA256(stringPayload).toString(CryptoJS.enc.Hex);
    return hash;
  }

  hmacSha256AsBytes(toSign, key) {
    return CryptoJS.HmacSHA256(key, toSign, { asBytes: true });
  }
}
