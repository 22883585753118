import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { LocalStorageService } from '../../local-storage/local-storage.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LastVisitedUrlService {
  lastVisitedRouterSubscription: Subscription;
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  setup() {
    const INVALID_LAST_VISITED_URL = ['login', 'wizard', 'error', 'logout'];
    if (
      !this.lastVisitedRouterSubscription ||
      this.lastVisitedRouterSubscription.closed
    ) {
      this.lastVisitedRouterSubscription = this.router.events.subscribe(
        (event: RouterEvent) => {
          if (
            event instanceof NavigationEnd &&
            !INVALID_LAST_VISITED_URL.some(item => event.url.indexOf(item) > -1)
          ) {
            this.localStorageService.setItem(
              'AUTH.last-visited-url',
              event.url
            );
          }
        }
      );
    }
  }
}
