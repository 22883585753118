import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { routeAnimations } from '../core/animations/route.animations';

@Component({
  selector: 'snxt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit(): void {}
}
