import {
  EntityAction,
  EntityCollection,
  EntityCollectionReducerMethods,
  EntityDefinition
} from '@ngrx/data';
import {
  AdditionalTableState,
  EntityCollectionTableState
} from '../../../../shared/utility/table-collection-state/additional-table-state';
import { SearchParameters } from '../../base/model/search-parameters.model';
import { ElasticResponse } from '../model/elastic-response.model';

export class ElasticEntityCollectionReducerMethods<
  T
> extends EntityCollectionReducerMethods<T> {
  constructor(
    public entityName: string,
    public definition: EntityDefinition<T>
  ) {
    super(entityName, definition);
  }

  protected queryMany(
    collection: EntityCollection<T>,
    action: EntityAction<any>
  ): EntityCollection<T> {
    const ec = (super.queryMany(
      collection,
      action
    ) as any) as EntityCollectionTableState<T>;
    const queryParams = action.payload.data as SearchParameters;
    ec.pageIndex = queryParams.pageIndex;
    ec.pageSize = queryParams.pageSize;
    ec.searchText = queryParams.searchTerms;
    ec.sortColumn = queryParams.activeSortField;
    return ec;
  }

  protected queryManySuccess(
    collection: EntityCollection<T>,
    action: EntityAction<T[]>
  ): EntityCollection<T> {
    const ec = (super.queryAllSuccess(
      collection,
      action
    ) as any) as EntityCollectionTableState<T>;
    let payload = action.payload as any;
    ec.totalCount = payload.count;
    ec.filterOptions = payload.filterOptions;
    ec.loaded = true;
    ec.entities;
    return ec as any;
  }

  protected queryAllSuccess(
    collection: EntityCollection<T>,
    action: EntityAction<T[]>
  ): EntityCollection<T> {
    const ec = (super.queryAllSuccess(
      collection,
      action
    ) as any) as AdditionalTableState;
    let payload = action.payload as any;
    ec.totalCount = payload.count;
    ec.filterOptions = payload.filterOptions;
    return ec as any;
  }
}
