export class ObjectUtil {
  /**
   * Performs a deep Object.assign(target, source) where assigns are applied recursively into nested existing objects on target that also exist in source.
   * @param target The object to be used as base. All of its parameters will be preserved even if they don't exist in source.
   * @param source The object to be used as the source of updates for target. All of its parameters that exist in target, will end up updating the values in target with their current values in source.
   */
  static ObjectAssignNestedObjects<T>(target: T, source: T): T {
    let compoundState = {};
    Object.keys(target).forEach(k => {
      if (typeof target[k] === 'object') {
        compoundState[k] = source[k]
          ? this.ObjectAssignNestedObjects(target[k], source[k])
          : target[k];
        return;
      }
      compoundState[k] = source[k] ? source[k] : target[k];
      return;
    });
    return compoundState as T;
  }
}
