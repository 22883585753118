import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { DefaultDataServiceConfig, EntityDataModule } from '@ngrx/data';
import { entityConfig } from './entity-metadata';
import { environment } from '../environments/environment';

import {
  EntityCollectionReducerMethodsFactory,
  PersistenceResultHandler
} from '@ngrx/data';
import { ElasticPersistenceResultHandler } from './core/search-adapters/elasticsearch/store/elastic-persistence-result-handler';
import { AdditionalEntityCollectionReducerMethodsFactory } from './core/search-adapters/base/reducer/additional-entity-collection-reducer-methods-factory.service';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: environment.awsServerUrl,
  timeout: 5000
};
@NgModule({
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,

    // core
    CoreModule,

    // app
    AppRoutingModule,
    EntityDataModule.forRoot(entityConfig)
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    {
      provide: PersistenceResultHandler,
      useClass: ElasticPersistenceResultHandler
    },
    {
      provide: EntityCollectionReducerMethodsFactory,
      useClass: AdditionalEntityCollectionReducerMethodsFactory
    }
  ]
})
export class AppModule {}
