import { createAction, props } from '@ngrx/store';
import { OAuthTokens } from '../model/oauth-tokens.model';
import { AuthIdentity } from '../model/auth-identity.model';
import { AuthIdentityCredentials } from '../model/auth-identity-credentials.model';
import { AuthError } from '../model/auth-error.model';

const SECTION = '[Auth]';

export const authInitiateLogin = createAction(
  SECTION + 'Initiate Login',
  props<{ redirectUrl: string }>()
);
export const authInitiateSilentLogin = createAction(
  SECTION + 'Initiate Silent Login',
  props<{ redirectUrl: string }>()
);
export const authFinalizeLoginSuccess = createAction(
  SECTION + 'Finalize Login Success'
);

export const authGetTokens = createAction(
  SECTION + 'Get Tokens',
  props<{ authCode: string; requestedUrl?: string }>()
);
export const authGetTokensSuccess = createAction(
  SECTION + 'Get Tokens Success',
  props<{
    tokens: OAuthTokens;
    userGroups: string[];
    email: string;
  }>()
);
export const authGetTokensFailure = createAction(
  SECTION + 'Get Tokens Failure',
  props<{ error: AuthError }>()
);

export const authLogout = createAction(SECTION + 'Logout');

export const authInitiateRefreshCredentials = createAction(
  SECTION + 'Initiate Refresh Credentials'
);
export const authFinalizeRefreshCredentialsSuccess = createAction(
  SECTION + 'Finalize Refresh Credentials Success'
);
export const authRefreshTokens = createAction(SECTION + 'Refresh Tokens');
export const authRefreshTokensSuccess = createAction(
  SECTION + 'Refresh Tokens Success',
  props<{ tokens: OAuthTokens; userGroups: string[]; email: string }>()
);
export const authRefreshTokensFailure = createAction(
  SECTION + 'Refresh Tokens Failure',
  props<{ error: AuthError }>()
);

export const authGetUserInfo = createAction(SECTION + 'Get User Info');
export const authGetUserInfoSuccess = createAction(
  SECTION + 'Get User Info Success',
  props<{ userInfo: any }>()
);
export const authGetUserInfoFailure = createAction(
  SECTION + 'Get User Info Failure',
  props<{ error: AuthError }>()
);

export const getIdentityId = createAction(SECTION + 'Get Identity Id');

export const getIdentitySuccess = createAction(
  SECTION + 'Get Identity Id Success',
  props<{ identity: AuthIdentity }>()
);

export const getIdentityFalure = createAction(
  SECTION + 'Get Identity Id Failure',
  props<{ error: AuthError }>()
);

export const getCredentialsForIdentity = createAction(
  SECTION + 'Get Credentials For Identity'
);

export const getCredentialsForIdentitySuccess = createAction(
  SECTION + ' Get Credentials For Identity Success',
  props<{ credentials: AuthIdentityCredentials }>()
);

export const getCredentialsForIdentityFailure = createAction(
  SECTION + ' Get Credentials For Identity Failure',
  props<{ error: AuthError }>()
);

export const fatalAuthError = createAction(
  SECTION + 'Fatal Error Screen',
  props<{ error: string }>()
);

export const setupInitialTasks = createAction(SECTION + 'Setup Initial Tasks');
