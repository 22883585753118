import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { USER_GROUP_PORTALS } from '../model/user-groups.enum';

@Injectable({
  providedIn: 'root'
})
export class JwtDecoderService {
  constructor() {}

  decodeToken(token: string) {
    return jwt_decode(token);
  }

  getCurrentUserAssignedGroups(token: string): string[] {
    try {
      let groups = this.decodeToken(token)['cognito:groups']?.map(groupName =>
        groupName.toLowerCase()
      );

      let existingPortalGroups = groups.filter(
        g => USER_GROUP_PORTALS[g.toUpperCase()]
      );
      return existingPortalGroups;
    } catch (error) {
      return [];
    }
  }

  getCurrentUserEmail(idToken: string) {
    let email = (this.decodeToken(idToken) as any).email;
    return email;
  }
}
