import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { ALARM_MESSAGE_STATE } from '../model/alarm-message-status.enum';
import { AlarmMessage } from '../model/alarm-message.model';
import { AlarmStatus } from '../model/alarm-status.model';
import * as AlarmActions from './alarm.actions';

export const alarmFeatureKey = 'alarm';

export interface AlarmState {
  alarmStatus: AlarmStatus[];
  activeAlarmId: string;
  isAlarmActive: boolean;
  isCreatingAlarm: boolean;
  isCancelingAlarm: boolean;
  isConnectedWebSocket: boolean;
  isLoadedLastStatus: boolean;
  isLoadingLastStatus: boolean;
  isConnectingWebSocket: boolean;
}

export const initialState: AlarmState = {
  alarmStatus: [],
  activeAlarmId: '',
  isAlarmActive: false,
  isCreatingAlarm: false,
  isCancelingAlarm: false,
  isConnectedWebSocket: false,
  isLoadedLastStatus: false,
  isLoadingLastStatus: false,
  isConnectingWebSocket: false
};

export const alarmReducer = createReducer(
  initialState,
  on(AlarmActions.loadAlarms, (state, action) => ({
    ...state,
    isLoadingLastStatus: true
  })),
  on(AlarmActions.loadAlarmsSuccess, (state, action) => ({
    ...state,
    isLoadingLastStatus: false,
    isLoadedLastStatus: true,
    alarmStatus: [...state.alarmStatus, ...action.alarmStatus],
    activeAlarmId: AlarmReducerHelper.getActiveAlarmIdOrDefaultPrevious(
      action.alarmStatus,
      state.activeAlarmId
    )
  })),
  on(AlarmActions.loadAlarmsFailure, (state, action) => ({
    ...state,
    isLoadingLastStatus: false,
    isLoadedLastStatus: false
  })),
  on(AlarmActions.setupAlarmSocket, (state, action) => ({
    ...state,
    isConnectingWebSocket: true
  })),
  on(AlarmActions.updateAlarmStatus, (state, action) => ({
    ...state,
    isConnectingWebSocket: false,
    isConnectedWebSocket: true,
    alarmStatus: [...state.alarmStatus, action.status],
    activeAlarmId: AlarmReducerHelper.getActiveAlarmIdOrDefaultPrevious(
      [action.status],
      state.activeAlarmId
    )
  })),
  on(AlarmActions.setOffAlarm, (state, action) => ({
    ...state,
    isCreatingAlarm: true
  })),
  on(AlarmActions.setOffAlarmFailure, (state, action) => ({
    ...state,
    isCreatingAlarm: false
  })),
  on(AlarmActions.setOffAlarmRequestFailure, (state, action) => ({
    ...state,
    isCreatingAlarm: false
  })),
  on(AlarmActions.setOffAlarmRequestSuccess, (state, action) => ({
    ...state,
    isCreatingAlarm: true
  })),
  on(AlarmActions.alarmActivated, (status, action) => ({
    ...status,
    isAlarmActive: true,
    isCreatingAlarm: false
  })),
  on(AlarmActions.cancelAlarm, (state, action) => ({
    ...state,
    isCancelingAlarm: true
  })),
  on(AlarmActions.cancelAlarmSuccess, (state, action) => ({
    ...state,
    isAlarmActive: false,
    isCancelingAlarm: false
  })),
  on(AlarmActions.cancelAlarmFailure, (state, action) => ({
    ...state,
    isCancelingAlarm: false
  }))
);

export const selectAlarmState = createFeatureSelector<AlarmState>(
  alarmFeatureKey
);

class AlarmReducerHelper {
  static getActiveAlarmId(statuses: AlarmStatus[]) {
    const activeAlarmId = (<AlarmMessage>(
      statuses.find(
        s => s.status.toLowerCase() === ALARM_MESSAGE_STATE.ACTIVE.toLowerCase()
      )
    ))?.id;
    return activeAlarmId;
  }

  static getActiveAlarmIdOrDefaultPrevious(
    statuses: AlarmStatus[],
    previousId: string
  ) {
    if (
      statuses.some(
        s => s.status.toLowerCase() === ALARM_MESSAGE_STATE.ACTIVE.toLowerCase()
      )
    ) {
      let id = this.getActiveAlarmId(statuses);
      return id ? id : previousId;
    }

    return previousId;
  }
}
