import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { GroupMediatorService } from '../services/group-mediator.service';
import {
  selectUserAssignedGroups,
  selectIsAuthenticated
} from '../store/auth.selectors';
import { first, map, withLatestFrom } from 'rxjs/operators';
import { USER_GROUP_PORTALS } from '../model/user-groups.enum';

@Injectable({
  providedIn: 'root'
})
export class EmployeeGuard implements CanActivate, CanLoad {
  guardRole = USER_GROUP_PORTALS.EMPLOYEE;

  constructor(
    private store: Store,
    private groupMediatorService: GroupMediatorService
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.store.select(selectUserAssignedGroups).pipe(
      first(),
      map(userGroups =>
        this.groupMediatorService.canLoadModule(
          userGroups,
          this.guardRole,
          segments
        )
      )
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(selectUserAssignedGroups).pipe(
      withLatestFrom(this.store.select(selectIsAuthenticated)),
      map(([userGroups, isAuthenticated]) => {
        return this.groupMediatorService.canGroupActivateModule(
          isAuthenticated,
          userGroups,
          this.guardRole,
          state.url
        );
      })
    );
  }
}
