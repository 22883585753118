import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectIsAuthenticated } from '../../../core/core.module';
import { tap, first } from 'rxjs/operators';
import { noop } from 'rxjs';

@Component({
  selector: 'snxt-error-screen',
  templateUrl: './error-screen.component.html',
  styleUrls: ['./error-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorScreenComponent implements OnInit, OnDestroy {
  errorMessage: string;

  constructor(private router: Router, private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectIsAuthenticated)
      .pipe(
        first(),
        tap(isAuthenticated => {
          if (isAuthenticated === undefined) {
            this.router.navigateByUrl('login');
          }
        })
      )
      .subscribe(noop);
    const stateError = this.router.getCurrentNavigation()?.extras?.state
      ?.console?.error;
    this.errorMessage = stateError
      ? stateError
      : 'Please try reloading the page or contact support if the problem persists.';
  }

  ngOnDestroy() {}
}
