import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { first, withLatestFrom, switchMap, filter } from 'rxjs/operators';
import { StringUtility } from '../../shared/utility/string-utility';
import * as AuthSelectors from '../auth/store/auth.selectors';
import { authRefreshTokens } from '../auth/store/auth.actions';
import { environment } from '../../../environments/environment';
import { AuthRequestSignatureService } from '../signature/services/auth-request-signature.service';

const NO_AUTH_URLS = [
  'dev-prepaytolls-files-imported',
  'oauth2/token',
  'oauth2/authorize',
  'logout',
  '/assets',
  environment.AwsUrls.cognitoIdpUrl.replace(
    '{region}',
    environment.Auth.region
  ),
  environment.AwsUrls.cognitoIdentityUrl.replace(
    '{region}',
    environment.Auth.region
  )
];
@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const store = this.injector.get(Store);

    if (StringUtility.stringContainsItemFromArray(request.url, NO_AUTH_URLS)) {
      return next.handle(request);
    }

    return store.pipe(
      select(AuthSelectors.selectIsRefreshingCredentials),
      withLatestFrom(
        store.pipe(select(AuthSelectors.selectTokenExpirationTime)),
        store.select(AuthSelectors.selectAccessExpirationDate),
        store.select(AuthSelectors.selectSessionToken),
        store.select(AuthSelectors.selectSecretKey),
        store.select(AuthSelectors.selectAccessKey)
      ),
      switchMap(
        ([
          isRefreshing,
          tokensExpirationTime,
          accessExpirationTime,
          sessionToken,
          secretKey,
          accessKey
        ]) => {
          const isTokensExpired = this.isExpiredOrNearExpiration(
            tokensExpirationTime
          );
          const isAccessKeyExpired = this.isExpiredOrNearExpiration(
            accessExpirationTime
          );
          const isExpired = isTokensExpired || isAccessKeyExpired;
          const authorizedRequest = this.authorizeRequestSignV4(
            request,
            sessionToken,
            secretKey,
            accessKey
          );
          if (!isExpired) {
            return next.handle(authorizedRequest);
          }

          if (!isRefreshing) {
            store.dispatch(authRefreshTokens());
          }
          return store.select(AuthSelectors.selectIsRefreshingCredentials).pipe(
            first(),
            filter(isRefreshing => !isRefreshing),
            switchMap(() => next.handle(authorizedRequest))
          );
        }
      )
    );
  }

  private authorizeRequestBearerToken(request: HttpRequest<unknown>, token) {
    const authorizedRequest = request.clone({
      setHeaders: { Authorization: 'Bearer ' + token }
    });
    return authorizedRequest;
  }

  private authorizeRequestSignV4(
    request: HttpRequest<unknown>,
    sessionToken: string,
    secretKey: string,
    accessKey: string
  ): HttpRequest<unknown> {
    const signatureService = this.injector.get(AuthRequestSignatureService);
    return signatureService.signRequest(
      sessionToken,
      secretKey,
      accessKey,
      request
    );
  }

  private isExpiredOrNearExpiration(isoDate: string): boolean {
    const expiration = new Date(isoDate);
    const now = new Date();
    const secondsDiff = Math.floor(
      (this.getUTCDate(expiration) - this.getUTCDate(now)) / 1000
    );
    const minimumSecondDiffForRenewal = 5 * 60;
    if (secondsDiff > minimumSecondDiffForRenewal) {
      return false;
    }
    return true;
  }

  private getUTCDate(date: Date) {
    return Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    );
  }
}
