import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './core/auth/components/login/login.component';
import { LogoutComponent } from './core/auth/components/logout/logout.component';
import { ErrorScreenComponent } from './shared/components/error-screen/error-screen.component';
import { AdminGuard } from './core/auth/guard/admin.guard';
import { GuestGuard } from './core/auth/guard/guest.guard';
import { EmployeeGuard } from './core/auth/guard/employee.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'guest',
    canLoad: [GuestGuard],
    canActivate: [GuestGuard],
    loadChildren: () =>
      import('./features/guest/guest.module').then(m => m.GuestModule)
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    canLoad: [AdminGuard],
    loadChildren: () =>
      import('./features/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'employee',
    canActivate: [EmployeeGuard],
    canLoad: [EmployeeGuard],
    loadChildren: () =>
      import('./features/employee/employee.module').then(m => m.EmployeeModule)
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./features/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  { path: 'logout', component: LogoutComponent },
  { path: 'error', component: ErrorScreenComponent },
  {
    path: '**',
    redirectTo: 'employee'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
