import { Injectable } from '@angular/core';
import {
  EntityCollectionReducerMethodMap,
  EntityDefinitionService
} from '@ngrx/data';
import { ElasticEntityCollectionReducerMethods } from '../../elasticsearch/store/elastic-entity-collection-reducer-methods';

@Injectable({
  providedIn: 'root'
})
export class AdditionalEntityCollectionReducerMethodsFactory {
  constructor(private entityDefinitionService: EntityDefinitionService) {}

  create<T>(entityName: string): EntityCollectionReducerMethodMap<T> {
    const definition = this.entityDefinitionService.getDefinition<T>(
      entityName
    );
    const methodsClass = new ElasticEntityCollectionReducerMethods(
      entityName,
      definition
    );
    return methodsClass.methods;
  }
}
