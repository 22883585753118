export enum AWS_V4_SIGNATURE_PARAMETERS {
  // The algorithm you're using in the signing process. The only valid value is AWS4-HMAC-SHA256.
  X_AMZ_ALGORITHM = 'X-Amz-Algorithm',

  // A string separated by slashes ("/") that is formed by concatenating your access key ID and your credential scope components. Credential scope includes the date in YYYYMMDD format, the AWS Region, the service name, and a special termination string (aws4_request).
  X_AMZ_CREDENTIAL = 'X-Amz-Credential',

  // The date and time that the signature was created. Generate the date and time by following the instructions in Handling Dates in Signature Version 4 in the Amazon Web Services General Reference.
  X_AMZ_DATE = 'X-Amz-Date',

  // The length of time in seconds until the credentials expire. The maximum value is 300 seconds (5 minutes).
  X_AMZ_EXPIRES = 'X-Amz-Expires',

  // Optional. A Signature Version 4 token for temporary credentials. If you specify this parameter, include it in the canonical request. For more information, see Requesting Temporary Security Credentials in the AWS Identity and Access Management User Guide.
  X_AMZ_SECURITY_TOKEN = 'X-Amz-Security-Token',

  // The Signature Version 4 signature that you generated for the request.
  X_AMZ_SIGNATURE = 'X-Amz-Signature',

  // The headers that are signed when creating the signature for the request. The only valid value is host.
  X_AMZ_SIGNEDHEADERS = 'X-Amz-SignedHeaders'
}
