import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectIsAuthenticated } from '../store/auth.selectors';
import { map } from 'rxjs/operators';
import { AppState } from '../../core.state';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(selectIsAuthenticated).pipe(
      map(isAuthenticated => {
        return isAuthenticated
          ? isAuthenticated
          : this.router.createUrlTree(['/login'], {
              queryParams: { state: state.url }
            });
      })
    );
  }
}
