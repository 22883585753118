// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const packageJson = require('../../../../package.json');

export const environment = {
  appName: 'Stomata',
  envName: 'DEV',
  appPrefix: 'snxt-stmt-',
  production: false,
  test: false,
  i18nPrefix: '',
  inMemoryApiUrl: 'https://api.dev.stomata.io/api/',
  awsServerUrl: 'https://api.dev.stomata.io/',
  webSocket: 'ws.dev.stomata.io',
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
    fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
    cypress: packageJson.devDependencies['cypress']
  },
  Auth: {
    hostedUiUrl: 'https://login.dev.stomata.io/',
    region: 'us-west-2',
    userPoolId: 'us-west-2_nwezeBGHO',
    identityPoolId: 'us-west-2:ac2f3f1e-0bec-46a8-bd4a-1e25f42119fe',

    clientId: '56cmuuvf59b9rrsaccevuusql8',
    response_type: 'code',
    scope: 'email+openid+profile',
    redirect_uri: 'https://secure.dev.stomata.io/login',
    redirect_logout_uri: 'https://secure.dev.stomata.io/logout',
    // redirect_uri: 'http://localhost:4200/login',
    // redirect_logout_uri: 'http://localhost:4200/logout',
    identityProvider: 'COGNITO'
  },
  AwsUrls: {
    cognitoIdpAuthority: 'cognito-idp.{region}.amazonaws.com',
    cognitoIdpUrl: 'https://cognito-idp.{region}.amazonaws.com/',
    cognitoIdentityUrl: 'https://cognito-identity.{region}.amazonaws.com/'
  }
};
