import { createAction, props } from '@ngrx/store';
import { WebSocketMessage } from '../model/web-socket-message.model';

export const connectWebSocket = createAction('[WebSocket] Connect WebSockets');

export const connectWebSocketSuccess = createAction(
  '[WebSocket] Connect WebSocket Success',
  props<{ data: any }>()
);

export const connectWebSocketFailure = createAction(
  '[WebSocket] Connect WebSocket Failure',
  props<{ error: any }>()
);

export const newWebSocketMessage = createAction(
  '[WebSocket] New Message',
  props<{ message: WebSocketMessage }>()
);

export const newWebSocketErrorMessage = createAction(
  '[WebSocket] New Error Message',
  props<{ error: any }>()
);

export const startKeepAliveSocket = createAction(
  '[WebSocket] Start Keep Alive'
);

export const stopKeepAliveSocket = createAction('[WebSocket] Stop Keep Alive');

export const closeSocket = createAction('[WebSocket] Close Socket');

export const keepAlive = createAction('[WebSocket] Keep Alive');
