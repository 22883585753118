import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { noop, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { environment } from '../../../../environments/environment';
import { WebSocketMessage } from '../model/web-socket-message.model';
import {
  newWebSocketErrorMessage,
  newWebSocketMessage
} from '../store/web-socket.actions';
import { AWS_V4_SIGNATURE_PARAMETERS } from '../../signature/model/aws-v4-signature-parameters.enum';
import { AuthRequestSignatureService } from '../../signature/services/auth-request-signature.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  constructor(
    private store: Store,
    private datePipe: DatePipe,
    private signatureService: AuthRequestSignatureService
  ) {}

  socket;
  setupWebSocket(accessKey: string, secretKey: string, sessionToken: string) {
    if (!!this.socket) {
      return;
    }
    const socketUrl = this.createPresignedUrl(
      accessKey,
      secretKey,
      sessionToken
    );
    this.socket = webSocket(socketUrl);

    this.socket
      .pipe(
        map(message =>
          this.store.dispatch(
            newWebSocketMessage({ message: message as WebSocketMessage })
          )
        ),
        catchError(error =>
          of(this.store.dispatch(newWebSocketErrorMessage({ error })))
        )
      )
      .subscribe(noop);
  }

  sendMessage(message: string) {
    this.socket.next({ message });
  }

  closeSocket() {
    this.socket.complete();
  }

  private createPresignedUrl(
    accessKey: string,
    secretKey: string,
    securityToken?: string
  ) {
    let method = 'GET';
    let awsService = 'execute-api';
    let host = environment.webSocket;
    let nowDate = new Date(Date.now());
    let amzDate = this.datePipe.transform(
      nowDate,
      "yyyyMMdd'T'HHmmss'Z'",
      '+0000'
    );
    let datestamp = this.datePipe.transform(nowDate, 'yyyyMMdd', '+0000');

    let canonicalHeaders = [`host:${host}`];
    let signedHeaders = 'host';
    let algorithm = 'AWS4-HMAC-SHA256';

    const credentialScope = `${datestamp}/${environment.Auth.region}/${awsService}/aws4_request`;

    let paramsQuery = new HttpParams()
      .set(AWS_V4_SIGNATURE_PARAMETERS.X_AMZ_SECURITY_TOKEN, securityToken)
      .set(AWS_V4_SIGNATURE_PARAMETERS.X_AMZ_DATE, amzDate)
      .set(AWS_V4_SIGNATURE_PARAMETERS.X_AMZ_ALGORITHM, algorithm)
      .set(
        AWS_V4_SIGNATURE_PARAMETERS.X_AMZ_CREDENTIAL,
        `${accessKey}/${credentialScope}`
      )
      .set(AWS_V4_SIGNATURE_PARAMETERS.X_AMZ_SIGNEDHEADERS, signedHeaders);

    const canonicalRequest = this.signatureService.getCanonicalRequest(
      '',
      method,
      canonicalHeaders,
      signedHeaders,
      '',
      paramsQuery
    );

    const stringToSign = this.signatureService.getStringToSign(
      amzDate,
      datestamp,
      canonicalRequest
    );

    const signingKey = this.signatureService.getSignatureKey(
      secretKey,
      datestamp,
      environment.Auth.region,
      awsService
    );
    const signature = this.signatureService.getSignature(
      signingKey,
      stringToSign
    );

    paramsQuery = paramsQuery.set(
      AWS_V4_SIGNATURE_PARAMETERS.X_AMZ_SIGNATURE,
      signature
    );

    let encodedUrlParams = new URLSearchParams();
    paramsQuery.keys().forEach(key => {
      encodedUrlParams.set(key, paramsQuery.get(key));
    });

    let queryString = '?' + encodedUrlParams.toString();

    const requestUrl = `wss://${host}${queryString}`;
    return requestUrl;
  }
}
