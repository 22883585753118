import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  filter,
  map,
  mapTo,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { interval } from 'rxjs';
import { WebSocketService } from '../service/web-socket.service';
import * as AuthActions from '../../auth/store/auth.actions';
import * as AuthSelectors from '../../auth/store/auth.selectors';
import * as WebSocketActions from './web-socket.actions';
import * as AlarmActions from '../../../features/alarm/store/alarm.actions';
import { AlarmStatus } from '../../../features/alarm/model/alarm-status.model';
import { USER_GROUP_PORTALS } from '../../auth/model/user-groups.enum';

const KEEP_ALIVE_INTERVAL = 300000;
const KEEP_ALIVE_MESSAGE = 'keepAlive';

@Injectable()
export class WebSocketEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private socketService: WebSocketService
  ) {}

  finalizeLoginSuccessSocketEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authFinalizeLoginSuccess),
      withLatestFrom(
        this.store.select(AuthSelectors.selectAccessKey),
        this.store.select(AuthSelectors.selectSecretKey),
        this.store.select(AuthSelectors.selectSessionToken),
        this.store.select(AuthSelectors.selectUserAssignedGroups)
      ),
      filter(([, , , , groups]) =>
        groups.some(
          g =>
            g === USER_GROUP_PORTALS.ADMIN || g === USER_GROUP_PORTALS.EMPLOYEE
        )
      ),
      map(([, accessKey, secretKey, sessionToken]) => {
        this.socketService.setupWebSocket(accessKey, secretKey, sessionToken);
        return WebSocketActions.startKeepAliveSocket();
      })
    )
  );

  startKeepAliveSocketEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(WebSocketActions.startKeepAliveSocket),
      switchMap(() => {
        return interval(KEEP_ALIVE_INTERVAL).pipe(
          mapTo(WebSocketActions.keepAlive()),
          takeUntil(
            this.store
              .select(AuthSelectors.selectStopKeepingSocketAlive)
              .pipe(filter(i => !!i))
          )
        );
      })
    )
  );

  keepAliveSocketEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WebSocketActions.keepAlive),
        tap(() => {
          this.socketService.sendMessage(KEEP_ALIVE_MESSAGE);
        })
      ),
    { dispatch: false }
  );

  closeSocketEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WebSocketActions.closeSocket),
        tap(() => {
          this.socketService.closeSocket();
        })
      ),
    { dispatch: false }
  );

  webSocketMessageEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(WebSocketActions.newWebSocketMessage),
      withLatestFrom(this.store.select(AuthSelectors.selectUserAssignedGroups)),
      filter(([action, groups]) =>
        groups.some(
          g =>
            g === USER_GROUP_PORTALS.ADMIN || g === USER_GROUP_PORTALS.EMPLOYEE
        )
      ),
      map(([action, groups]) => {
        return AlarmActions.updateAlarmStatus({
          status: action.message as AlarmStatus
        });
      })
    )
  );
}
