import { ActionReducer, INIT } from '@ngrx/store';
import { AppState } from '../core.state';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { ObjectUtil } from '../../shared/utility/object-utility';

export function initStateFromSessionStorage(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return function(state, action) {
    const newState = reducer(state, action);
    if (INIT.toString() === action.type) {
      const sessionStorageState = SessionStorageService.loadInitialState();
      const compoundNewState = ObjectUtil.ObjectAssignNestedObjects<AppState>(
        newState,
        sessionStorageState
      );
      return compoundNewState;
    }
    return newState;
  };
}
