import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import {
  authGetTokens,
  authInitiateLogin,
  authInitiateSilentLogin
} from '../../store/auth.actions';
import { take, map, withLatestFrom, tap } from 'rxjs/operators';
import {
  selectIsAuthenticated,
  selectLastVisitedUrl,
  selectRefreshToken
} from '../../store/auth.selectors';
import { noop, Observable } from 'rxjs';

const INVALID_REDIRECTS = ['login', 'error', 'logout'];

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLoading$: Observable<boolean>;
  error$: Observable<string>;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading$ = this.store
      .select(selectIsAuthenticated)
      .pipe(map(isAuthenticated => !isAuthenticated));
    const authCode = this.route.snapshot.queryParams.code;
    let requestedUrl: string = this.route.snapshot.queryParams.state;

    if (
      !!requestedUrl &&
      INVALID_REDIRECTS.some(ir => requestedUrl.includes(ir))
    ) {
      requestedUrl = '/';
    }

    if (!!authCode) {
      this.store.dispatch(authGetTokens({ authCode, requestedUrl }));
      return;
    }

    this.store
      .pipe(
        take(1),
        select(selectIsAuthenticated),
        withLatestFrom(
          this.store.select(selectLastVisitedUrl),
          this.store.select(selectRefreshToken)
        ),
        map(([isAuthenticated, lastUrl, refreshToken]) => {
          const redirectUrl = requestedUrl
            ? requestedUrl
            : lastUrl
            ? lastUrl
            : '';
          if (isAuthenticated) {
            return this.router.navigate([redirectUrl]);
          }
          if (refreshToken) {
            return this.store.dispatch(
              authInitiateSilentLogin({ redirectUrl })
            );
          }
          return this.store.dispatch(authInitiateLogin({ redirectUrl }));
        })
      )
      .subscribe(noop);
  }
}
