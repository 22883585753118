import { Injectable } from '@angular/core';
import { USER_GROUP_PORTALS } from '../model/user-groups.enum';
import { UrlTree, Router, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class GroupMediatorService {
  constructor(private router: Router, private store: Store) {}

  canGroupActivateModule(
    isAuthenticated: boolean,
    userGroups: string[],
    module: USER_GROUP_PORTALS,
    stateUrl: string
  ): boolean | UrlTree {
    if (!isAuthenticated) {
      return this.router.createUrlTree(['/login'], {
        queryParams: { state: stateUrl }
      });
    }
    if (this.canGroupLoadModule(userGroups, module)) {
      return true;
    }
    return this.router.createUrlTree([`/${userGroups[0]}`]);
  }

  private canGroupLoadModule(
    userGroups: string[],
    module: USER_GROUP_PORTALS
  ): boolean {
    if (userGroups?.some(ug => ug === module)) {
      return true;
    }
    return false;
  }

  canLoadModule(
    userGroups: string[],
    module: USER_GROUP_PORTALS,
    segments: UrlSegment[]
  ) {
    if (!userGroups || userGroups.length === 0) {
      let stateUrl = segments.map(s => s.path).join('/');
      this.router.navigateByUrl('login', { queryParams: { state: stateUrl } });
      return false;
    }
    const canLoadModule = this.canGroupLoadModule(userGroups, module);
    if (!canLoadModule) {
      this.router.navigateByUrl(`/${userGroups[0]}`);
      return false;
    }
    return true;
  }
}
