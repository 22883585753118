import { DefaultPersistenceResultHandler, EntityAction } from '@ngrx/data';
import { Action } from '@ngrx/store';
import { ElasticResponse } from '../model/elastic-response.model';

export class ElasticPersistenceResultHandler<
  T
> extends DefaultPersistenceResultHandler {
  handleSuccess(
    originalAction: EntityAction
  ): (data: ElasticResponse<T>) => Action {
    const actionHandler = super.handleSuccess(originalAction);

    return function(data: ElasticResponse<T>) {
      const action = actionHandler.call(this, data);
      let payload = (action as any).payload;

      if (data.aggregations) {
        payload.filterOptions = [];
        let aggregationNames = Object.keys(data.aggregations).filter(
          n => n !== 'count'
        );
        if (aggregationNames.length > 0) {
          aggregationNames.forEach(agg => {
            payload.filterOptions.push({
              field: agg.replace('-aggregation', ''),
              options: data.aggregations[agg].buckets.map(bucket => ({
                key: bucket.key,
                count: bucket.doc_count
              }))
            });
          });
        }
        payload.count = +data.aggregations?.count?.value ?? 0;
      }
      if (data.hits) {
        payload.data = data.hits.hits.map(hit => hit._source);
      }

      return action;
    };
  }
}
