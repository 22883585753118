import { createAction, props } from '@ngrx/store';
import { AlarmStatus } from '../model/alarm-status.model';
import { Alarm } from '../model/alarm.model';

const SECTION_NAME = '[Alarm] ';

export const initialAlarmSetup = createAction(
  SECTION_NAME + 'Initial Alarm Setup'
);

export const loadAlarms = createAction(SECTION_NAME + 'Load alarm last status');

export const loadAlarmsSuccess = createAction(
  SECTION_NAME + 'Load alarm last status Success',
  props<{ alarmStatus: AlarmStatus[] }>()
);

export const loadAlarmsFailure = createAction(
  SECTION_NAME + 'Load alarm last status Failure',
  props<{ error: any }>()
);

export const setupAlarmSocket = createAction(
  SECTION_NAME + 'Setup Alarm Socket'
);

export const openNewAlarmDialog = createAction(
  SECTION_NAME + 'Open new alarm dialog'
);

export const openActiveAlarmDialog = createAction(
  SECTION_NAME + 'Open Active Alarm Dialog'
);

export const setOffAlarm = createAction(
  SECTION_NAME + 'Set Off Alarm',
  props<{ alarm: Alarm }>()
);

export const setOffAlarmRequestSuccess = createAction(
  SECTION_NAME + 'Set Off Alarm Request Success'
);

export const setOffAlarmRequestFailure = createAction(
  SECTION_NAME + 'Set Off Alarm Request Failure'
);

export const setOffAlarmSuccess = createAction(
  SECTION_NAME + 'Set Off Alarm Success'
);

export const setOffAlarmFailure = createAction(
  SECTION_NAME + 'Set Off Alarm Failure'
);

export const cancelAlarm = createAction(
  SECTION_NAME + 'Cancel Alarm',
  props<{ pin?: string }>()
);

export const cancelAlarmRequestSuccess = createAction(
  SECTION_NAME + 'Cancel Alarm Request Success'
);

export const cancelAlarmRequestFailure = createAction(
  SECTION_NAME + 'Cancel Alarm Request Failure'
);

export const cancelAlarmSuccess = createAction(
  SECTION_NAME + 'Cancel Alarm Success'
);

export const cancelAlarmFailure = createAction(
  SECTION_NAME + 'Cancel Alarm FAilure'
);

export const updateAlarmStatus = createAction(
  SECTION_NAME + 'Update Action Status',
  props<{ status: AlarmStatus }>()
);

export const alarmActivated = createAction(
  SECTION_NAME + 'Alarm was activated'
);
