import { createSelector } from '@ngrx/store';
import { selectAuthState } from '../../core.state';

export const selectAuth = createSelector(selectAuthState, state => state);

export const selectIsAuthenticated = createSelector(
  selectAuth,
  state => state.isAuthenticated
);

export const selectAuthError = createSelector(selectAuth, auth => auth.error);

export const selectAuthVerifier = createSelector(
  selectAuth,
  state => state.verifier
);

export const selectIdToken = createSelector(selectAuth, state => state.idToken);

export const selectAccessToken = createSelector(
  selectAuth,
  state => state.accessToken
);

export const selectTokenExpirationTime = createSelector(
  selectAuth,
  state => state.tokenExpiration
);

export const selectRefreshToken = createSelector(
  selectAuth,
  state => state.refreshToken
);

export const selectIsRefreshingCredentials = createSelector(
  selectAuth,
  state => state.isRefreshingCredentials
);

export const selectLastVisitedUrl = createSelector(
  selectAuth,
  state => state.lastVisitedUrl
);

export const selectRequestedUrl = createSelector(
  selectAuth,
  state => state.requestedUrl
);

export const selectIdentityId = createSelector(
  selectAuth,
  state => state.identityId
);

export const selectSessionToken = createSelector(
  selectAuth,
  store => store.sessionToken
);

export const selectSecretKey = createSelector(
  selectAuth,
  store => store.secretAccessKey
);

export const selectAccessKey = createSelector(
  selectAuth,
  store => store.accessKeyId
);

export const selectAccessExpirationDate = createSelector(
  selectAuth,
  store => store.accessKeyExpirationDate
);

export const selectUserAssignedGroups = createSelector(
  selectAuth,
  store => store.userGroups
);

export const selectUserEmail = createSelector(selectAuth, store => store.email);

export const selectStopKeepingSocketAlive = createSelector(
  selectAuth,
  store => !store.isKeepingSocketAlive
);
